import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RoleFilterService } from '../../core/role-filter.service';
import { MonitorNl } from '../../core/translations/mdbos-monitor-nl';
import { CategoryIdFilter, Monitor, QuestionnaireType, RegistrationFilter, ReportVariable } from '../../core/webapi';
import { ReportType, reportTypeNL } from '../../portal/report-settings/report-settings.component';
import { FilterType, Filters, OptionGroupObj } from './questionnaire/filter.model';

const defaultFilters: Filters = {
  [FilterType.Province]: [],
  [FilterType.LibraryName]: [],
  [FilterType.Municipality]: [],
  [FilterType.LocationName]: [],
};

@Injectable({ providedIn: 'root' })
export class ReportFilterService {
  allProvinces: OptionGroupObj[] = [];
  allLibraries: OptionGroupObj[] = [];
  allLocations: OptionGroupObj[] = [];
  allMunicipalities: OptionGroupObj[] = [];

  provinces: OptionGroupObj[] = [];
  libraries: OptionGroupObj[] = [];
  locations: OptionGroupObj[] = [];
  municipalities: OptionGroupObj[] = [];

  filters: Filters = defaultFilters;
  hasFilters = false;
  hasLibraryUserData = true;
  registrationFilter: RegistrationFilter[] = [];
  filterChanged$ = new Subject<void>();

  constructor(private roleFilterService: RoleFilterService) {}

  initRegistrationFilter(filter: RegistrationFilter[], useLocationWithIkcCity = false) {
    this.filters.province = [];
    this.filters.libraryName = [];
    this.filters.municipality = [];
    this.filters.locationName = [];
    this.registrationFilter = filter;

    this.allProvinces = [...new Set(filter.map((x) => x.province).sort())].map((x) => ({ value: x }));
    this.allLibraries = [...new Set(filter.map((x) => x.libraryName).sort())].map((x) => ({ value: x }));
    this.allLocations = [...filter.sort((a, b) => (a.locationName > b.locationName ? 1 : -1))].map((x) => ({
      value: x.brinLrkIsilCode,
      display: useLocationWithIkcCity ? `${x.locationName} (${x.ikcCity})` : `${x.locationName} (${x.brinLrkIsilCode})`,
    }));
    this.allMunicipalities = [...new Set(filter.map((x) => x.municipality).sort())].map((x) => ({ value: x }));

    this.provinces = [...this.allProvinces];
    this.libraries = [...this.allLibraries];
    this.locations = [...this.allLocations];
    this.municipalities = [...this.allMunicipalities];

    this.setRoleFilter();
    this.setFilters();
  }

  addFilter(event: OptionGroupObj[], filter: FilterType) {
    this.filters[filter] = event;
    this.setFilters();
  }

  removeFilter(value: OptionGroupObj, filterType: string) {
    const filter = filterType as FilterType;
    const index = this.filters[filter].indexOf(value);
    if (index !== -1) {
      this.filters[filter].splice(index, 1);
    }
    this.setFilters();
  }

  setFilters() {
    this.setProvinces();
    this.setMunicipalities();
    this.setLibraries();
    this.setLocations();
    this.hasFilters = Object.values(this.filters).some((entries) => entries.length > 0);
  }

  setProvinces() {
    const filter = this.getFilteredLocations(FilterType.Province);
    const keys = filter.map((y) => y.province);
    this.provinces = keys.length ? this.allProvinces.filter((x) => keys.includes(x.value)) : [...this.allProvinces];
  }

  setMunicipalities() {
    const filter = this.getFilteredLocations(FilterType.Municipality);
    const keys = filter.map((y) => y.municipality);
    this.municipalities = keys.length ? this.allMunicipalities.filter((x) => keys.includes(x.value)) : [...this.allMunicipalities];
  }

  setLibraries() {
    const filter = this.getFilteredLocations(FilterType.LibraryName);
    const keys = filter.map((y) => y.libraryName);
    this.libraries = keys.length ? this.allLibraries.filter((x) => keys.includes(x.value)) : [...this.allLibraries];
  }

  setLocations() {
    const filter = this.getFilteredLocations(FilterType.LocationName);
    const keys = filter.map((x) => x.brinLrkIsilCode);
    this.locations = keys.length ? this.allLocations.filter((x) => keys.includes(x.value)) : [...this.allLocations];
  }

  getFilteredLocations(exclude?: FilterType) {
    const filter = this.filters;
    const provinces = exclude === FilterType.Province || !(FilterType.Province in filter) ? [] : filter[FilterType.Province].map((y) => y.value);
    const libraries =
      exclude === FilterType.LibraryName || !(FilterType.LibraryName in filter) ? [] : filter[FilterType.LibraryName].map((y) => y.value);
    const locations =
      exclude === FilterType.LocationName || !(FilterType.LocationName in filter) ? [] : filter[FilterType.LocationName].map((y) => y.value);
    const municipalities =
      exclude === FilterType.Municipality || !(FilterType.Municipality in filter) ? [] : filter[FilterType.Municipality].map((y) => y.value);
    return this.registrationFilter.filter(
      (x) =>
        (!provinces.length || provinces.includes(x.province)) &&
        (!libraries.length || libraries.includes(x.libraryName)) &&
        (!locations.length || locations.includes(x.brinLrkIsilCode)) &&
        (!municipalities.length || municipalities.includes(x.municipality)),
    );
  }

  getSelectedFilter() {
    return this.getFilteredLocations().map((x) => x.brinLrkIsilCode);
  }

  getPreSelectDefinitionSettingsKey(year: number, portal: Monitor, reportType: ReportType, questionnaireType: QuestionnaireType | null = null) {
    const prefix = `${year}_voorselectie_`;
    const suffix = `_${reportTypeNL[reportType]}`;
    if (!questionnaireType) {
      return `${prefix}${MonitorNl[portal]}${suffix}`;
    }
    return `${prefix}${QuestionnaireType[questionnaireType]}${suffix}`;
  }

  getLocationFilterAsCategoryIdFilter(reportVariables: ReportVariable[]): CategoryIdFilter[] {
    const result = reportVariables.filter((x) => x.variableName.toLowerCase() === 'brin_lrk_vestiging');
    const brinLrk = result.length ? result[0] : reportVariables.filter((x) => x.variableName.toLowerCase() === 'isilcode')[0];

    const categoryId = brinLrk.categories[0].categoryId;
    const locations = this.getFilteredLocations().map((x) => x.brinLrkIsilCode);
    const locationFilter: CategoryIdFilter = { categoryId, answers: locations };
    return [locationFilter];
  }

  private setRoleFilter() {
    if (this.roleFilterService.canFilterAll) {
      return;
    }
    const libraryName = this.roleFilterService.userLibraryName;
    if (libraryName) {
      this.filters[FilterType.LibraryName] = [{ value: libraryName }];
      this.hasLibraryUserData = this.libraries.map((x) => x.value).includes(libraryName);
    }
  }
}
