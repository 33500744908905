import { QuestionnaireType } from '../../../core/webapi';
import { ISelectedQuestion, ReportGroup } from '../../../shared/report-types';
import { CategoryCaseFilter } from './case-selection/case-filter.service';

export enum FilterType {
  Province = 'province',
  LibraryName = 'libraryName',
  Municipality = 'municipality',
  LocationName = 'locationName',
}

export type OptionGroupObj = { value: string; display?: string };

export type Filters = { [key in FilterType]: OptionGroupObj[] };

export type SavedFilter = {
  name: string;
  filters: Filters;
};

export type QuestionnaireReportFilters = Record<QuestionnaireType, QuestionnaireFilter[]>;

export type QuestionnaireFilter = {
  name: string;
  categories: CategoryCaseFilter[];
  reportGroups: ReportGroup[];
  questions: ISelectedQuestion[];
};
