import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatQuillComponent } from '@shared/ui/mat-quill';
import { IReportSetting } from '../report-settings.service';

@Component({
  selector: 'pma-edit-report-setting-title',
  templateUrl: './edit-report-setting-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatIcon, MatFormField, MatLabel, MatQuillComponent, FormsModule, MatTooltip],
})
export class EditReportSettingTitleComponent implements OnInit {
  readonly toolbar = {
    container: [['bold', 'italic', 'underline'], [{ header: [1, 2, 3, 4, false] }], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']],
  };
  title = '';
  @Input() reportSetting!: IReportSetting;
  @Output() saveTitle = new EventEmitter();
  editMode = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.title = this.reportSetting.title ?? '';
  }

  onSave() {
    this.editMode = false;
    this.reportSetting.title = this.title;
    this.saveTitle.emit();
    this.cdr.markForCheck();
  }

  onCancel() {
    this.editMode = false;
    this.cdr.markForCheck();
  }

  onSetEditMode() {
    this.editMode = true;
    this.cdr.markForCheck();
  }
}
