<form #form="ngForm" (ngSubmit)="save(form)">
  <div class="d-flex align-items-start gap-2">
    <!-- Type vragenlijst -->
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Type vragenlijst</mat-label>
      <mat-select
        [(ngModel)]="questionnaireType"
        name="questionnaireType"
        #questionnaireTypeModel="ngModel"
        (selectionChange)="onSelectionChanged()"
        required
      >
        <mat-option *ngFor="let qt of questionnaireTypes" [value]="qt">
          {{ getQuestionnaireName(qt) }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="questionnaireTypeModel.hasError('required')">Het veld 'Vragenlijst' is verplicht.</mat-error>
    </mat-form-field>

    <!-- Vraag -->
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Vraag</mat-label>
      <mat-select [(ngModel)]="variable" name="vraag" #question="ngModel" required>
        <ng-container *ngFor="let monitorReportQuestions of monitorReportQuestions">
          <mat-optgroup
            *ngIf="monitorReportQuestions.questionnaire === getQuestionnaireName(questionnaireType)"
            [label]="monitorReportQuestions.questionnaire"
          >
            <mat-option
              class="multiline-select py-1"
              *ngFor="let displayQuestion of monitorReportQuestions.reportQuestions"
              [value]="displayQuestion.key.variable"
            >
              <div class="line-clamp-2">
                {{ displayQuestion.text }}
              </div>
            </mat-option>
          </mat-optgroup>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="question.hasError('required')">Het veld 'Vraag' is verplicht.</mat-error>
    </mat-form-field>

    <!-- Vergelijking -->
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Vergelijking</mat-label>
      <mat-select
        [(ngModel)]="selectedGroups"
        multiple
        name="vergelijking"
        #comparison="ngModel"
        panelClass="compare-panel"
        (selectionChange)="orderCompareSelection($event)"
      >
        <mat-select-trigger>
          <span class="d-flex align-items-center compare-trigger">
            <ng-container *ngFor="let selected of selectedGroups | orderBy: 'order' | pluck: 'text'; let first = first; let count = count">
              {{ selected }}
              <ng-container *ngIf="first && count > 1">
                <mat-icon class="px-1">compare_arrows</mat-icon>
              </ng-container>
            </ng-container>
          </span>
        </mat-select-trigger>
        <mat-option
          [disabled]="comparison.value?.length >= 2 && !(reportGroup | lsuPipesSome: selectedGroups)"
          *ngFor="let reportGroup of reportGroups"
          [value]="reportGroup"
        >
          {{ reportGroup.text }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="comparison.hasError('required')">Het veld 'Vergelijking' is verplicht.</mat-error>
      <mat-hint>Er mogen maximaal 2 onderdelen geselecteerd worden.</mat-hint>
    </mat-form-field>

    <div *ngIf="isGroupByCountrySelected()" class="w-100 mt-1">
      <mat-checkbox [(ngModel)]="onlyCountryFilter" name="onlyCountryFilter">Alleen landelijk</mat-checkbox>
    </div>

    <!-- Case-selectie -->
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Case-selectie</mat-label>
      <mat-select [(ngModel)]="caseSelection" panelClass="case-filter" name="categoryIdForCaseSelection">
        <mat-option [value]="null"> Geen </mat-option>
        <mat-optgroup *ngFor="let question of allowedQuestions" [label]="question.variableDescription">
          <ng-container *ngIf="question.categories.length > 50"><mat-error>Selectie bevat te veel antwoorden.</mat-error> </ng-container>
          <ng-container *ngIf="question.categories.length <= 50"
            ><mat-option
              *ngFor="let category of question.categories"
              [value]="{ value: category.value, answer: category.value, variableName: question.variableName }"
            >
              {{ category.description }}</mat-option
            >
          </ng-container>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Titel -->
  <div class="d-flex mt-2">
    <mat-form-field appearance="fill" floatLabel="always" class="flex-grow-1 w-100">
      <mat-label>Voer hier een titel in</mat-label>
      <lsu-mat-quill [modules]="{ toolbar }" name="questionText" [(ngModel)]="title"></lsu-mat-quill>
    </mat-form-field>
  </div>

  <div class="d-flex pb-4">
    <div class="mt-1">
      <button pmaMdBos mat-raised-button color="primary">Voeg voorselectie toe</button>
    </div>
  </div>
</form>
