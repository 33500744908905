import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOptgroup, MatOption } from '@angular/material/core';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatSelect, MatSelectChange, MatSelectTrigger } from '@angular/material/select';
import { MatQuillComponent } from '@shared/ui/mat-quill';
import { SomePipe } from '@shared/ui/pipes';
import { NgArrayPipesModule } from 'ngx-pipes';
import { getQuestionnaireTypes } from '../../../core/translations/get-questionnaire-types';
import { QuestionnaireTypeNl } from '../../../core/translations/questionnairetype-nl';
import { Monitor, QuestionnaireType, ReportGroupDefinition } from '../../../core/webapi';
import { MatMdBosIconDirective } from '../../../shared/mdbos-button.directive';
import { ISelectedQuestion, ReportGroup } from '../../../shared/report-types';
import { IMonitorReportQuestions, IQuestionDisplay, IReportSetting, IReportSettingKey, ReportSettingsService } from '../report-settings.service';

export type EditReportSettings = {
  monitor: Monitor;
  reportSettings: IReportSetting[];
  caseSelections: ISelectedQuestion[][];
  isIKC: boolean;
};

export interface SelectionGroup extends IQuestionDisplay {
  order: number;
}

export interface CaseSelection {
  value: string;
  variableName: string;
  answer: string | null;
}

@Component({
  selector: 'pma-edit-report-setting',
  templateUrl: './edit-report-setting.component.html',
  styleUrls: ['./edit-report-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    NgFor,
    MatOption,
    NgIf,
    MatError,
    MatOptgroup,
    MatSelectTrigger,
    MatIcon,
    MatHint,
    MatCheckbox,
    MatQuillComponent,
    MatMdBosIconDirective,
    MatButton,
    NgArrayPipesModule,
    SomePipe,
  ],
})
export class EditReportSettingComponent {
  reportSettings: IReportSetting[] = [];
  monitorReportQuestions: IMonitorReportQuestions[] = [];
  reportGroups: IQuestionDisplay[] = [];
  questionnaireTypes: QuestionnaireType[] = [];
  questionnaireType!: QuestionnaireType;
  variable: string | null = null;
  selectedGroups: SelectionGroup[] = [];
  onlyCountryFilter = false;
  caseSelection: CaseSelection | null = null;
  allowedQuestions: ISelectedQuestion[] = [];
  caseSelections: ISelectedQuestion[][] = [];
  title = '';
  toolbar = {
    container: [['bold', 'italic', 'underline'], [{ header: [1, 2, 3, 4, false] }], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']],
  };
  @Output() saved: EventEmitter<IReportSetting> = new EventEmitter<IReportSetting>();

  @Input() set data(data: EditReportSettings | undefined) {
    if (!data) {
      return;
    }
    this.caseSelections = data.caseSelections;
    this.reportSettings = data.reportSettings;
    this.questionnaireTypes = getQuestionnaireTypes(data.monitor, data.isIKC);
    this.monitorReportQuestions = this.reportSettingsService.getMonitorReportQuestions(data.monitor, data.isIKC);
    this.questionnaireType = this.questionnaireTypes[0];
    this.onSelectionChanged();
  }

  constructor(private reportSettingsService: ReportSettingsService) {}

  async save(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const editReportSetting = { key: {} as IReportSettingKey } as IReportSetting;
    editReportSetting.key.questionnaireType = this.questionnaireType;
    editReportSetting.key.variable = this.variable!;
    editReportSetting.title = this.title;
    editReportSetting.vergelijkingen =
      this.selectedGroups.length > 0 ? this.selectedGroups.sort((a, b) => a.order - b.order).map((x) => x.key.variable) : [];
    editReportSetting.onlyCountryFilter = this.onlyCountryFilter;
    editReportSetting.caseSelection = this.caseSelection;

    this.saved.emit(editReportSetting);
  }

  onSelectionChanged() {
    this.variable = null;
    this.reportGroups = this.reportSettingsService.getDisplayGroups(this.questionnaireType);
    this.onlyCountryFilter = false;
    this.allowedQuestions = this.caseSelections[this.questionnaireTypes.indexOf(this.questionnaireType)];
    this.selectedGroups = [];
  }

  orderCompareSelection(event: MatSelectChange) {
    const source = event.source;
    source.value = source.value.map((val: ReportGroup) => {
      if (!val.order || source.value.length === 1) {
        val.order = source.value.length;
      }
      return val;
    });
    source.options.forEach((option) => {
      if (!source.value.some((val: ReportGroupDefinition) => val.variable === option.value.variable)) {
        delete option.value.order;
      }
    });
    if (!this.isGroupByCountrySelected()) {
      this.onlyCountryFilter = false;
    }
  }

  getQuestionnaireName = (x: QuestionnaireType) => QuestionnaireTypeNl[x];

  isGroupByCountrySelected(): boolean {
    return this.selectedGroups.find((sg) => sg.key.variable === 'Landelijk') !== undefined;
  }
}
