<div class="d-flex align-items-center mat-headline-6" *ngIf="monitor !== -1 && monitor !== 0 && reportType !== 0">
  <div>Voorselectie {{ getTitle(monitor) }}: {{ getReportType(reportType) }}</div>
</div>

<mat-card>
  <mat-card-content>
    <mat-form-field appearance="fill" class="me-2 w-25">
      <mat-label>Rapportjaar</mat-label>
      <mat-select [(ngModel)]="measurementYear" (selectionChange)="onYearChanged()" name="reportYear">
        <mat-option *ngFor="let year of measurementYears" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <pma-edit-report-setting [data]="data" (saved)="addReportSetting($event)"></pma-edit-report-setting>

    <div *ngIf="!reportSettings.length">Geen resultaten gevonden. Voeg een voorselectie toe.</div>
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <div class="mat-headline-6" *ngIf="reportSettings.length">Voorselecties</div>
      <div cdkDrag *ngFor="let reportSetting of reportSettings; let i = index; let first = first" [cdkDragDisabled]="reportSettings.length <= 1">
        <div class="pb-2">
          <mat-divider *ngIf="!first"></mat-divider>
        </div>

        <div class="d-flex align-items-center mb-2">
          <button
            [disabled]="reportSettings.length <= 1"
            class="cursor--move"
            mat-icon-button
            type="button"
            matTooltip="Verander de volgorde van de voorselectie door naar boven of beneden te slepen"
            cdkDragHandle
          >
            <mat-icon>drag_indicator</mat-icon>
          </button>
          <div class="w-50">
            <div class="mat-subtitle-1 mb-0 w-100">
              <span class="fw-bold">Vragenlijst:</span> {{ getQuestionnaireName(reportSetting.key.questionnaireType) }}
            </div>
            <div class="mat-subtitle-1 mb-0 w-100"><span class="fw-bold">Vraag:</span> {{ getQuestionDisplay(reportSetting).text }}</div>
            <div class="mat-subtitle-1 mb-0 w-100"><span class="fw-bold">Case selectie:</span> {{ getCaseSelection(reportSetting) }}</div>
            <div class="mat-subtitle-1 mb-0 w-100" appearance="fill">
              <span class="fw-bold">Titel:</span>
              <pma-edit-report-setting-title [reportSetting]="reportSetting" (saveTitle)="save()"></pma-edit-report-setting-title>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center mb-2">
          <mat-chip-listbox>
            <mat-chip-option *ngFor="let vergelijking of reportSetting.vergelijkingen; let j = index">
              {{ getGroupReportText(vergelijking, i, reportSetting.onlyCountryFilter) }}
            </mat-chip-option>
          </mat-chip-listbox>
          <div class="px-2 my-1 align-self-stretch">
            <mat-divider vertical class="h-100"></mat-divider>
          </div>

          <button mat-icon-button type="button" matTooltip="Verwijder de voorselectie" (click)="onDelete(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
