<mat-icon *ngIf="!editMode" (click)="onSetEditMode()">edit</mat-icon>
<div class="d-flex">
  <div *ngIf="!editMode" [innerHTML]="title"></div>
  <mat-form-field *ngIf="editMode" appearance="fill" floatLabel="always" class="w-100">
    <mat-label>Voer hier een titel in</mat-label>
    <lsu-mat-quill [modules]="{ toolbar }" [(ngModel)]="title"></lsu-mat-quill>
  </mat-form-field>
  <mat-icon *ngIf="editMode" (click)="onSave()" matTooltip="Opslaan">save</mat-icon>
  <mat-icon *ngIf="editMode" (click)="onCancel()" matTooltip="Annuleren">cancel</mat-icon>
</div>
